<template>
<div class="s-layout" :style="colorObject">
  <div class="s-container">
    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />

    <Header
      :meta="meta"
      :page-title="getModuleConfig('page_title')"
      :page-image="getModuleConfig('page_image')"
      :page-kv-image="getModuleConfig('page_kv_image')"
      :header-section="getModuleConfig('header_section')"
    />

    <div class="page">
      <div class="py-4 text-center">
        <img src="@/assets/images/icon-check-circle.svg" />
        <div class="mt-3 font-weight-bold">預約已成功送出</div>
      </div>

      <div class="p-3 liff-reservation-form">
        <div
          v-for="(page, index) in visiblePages"
          :key="index"
        >
          <b-form-group
            :label="subject.title"
            label-cols="12"
            label-cols-sm="6"
            label-size="sm"
            label-for="input-sm"
            class="mb-3"
            v-for="(subject, i) in subjectsOfCurrentPageWithoutSection(page._id)"
            :key="i"
          >
            <div class="liff-reservation-form__field-value text-right">{{ getFieldValue(subject) }}</div>
          </b-form-group>

          <template v-for="(section, i) in sectionsOfCurrentPage(page._id)">
            <b-card
              header-tag="header"
              class="mb-3 liff-reservation-form__card"
              :key="`section${i}`"
              v-if="subjectsOfCurrentSection(section).length > 0"
            >
              <template #header>
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0">{{ section.title }}</h6>
                </div>
              </template>

              <b-form-group
                :label="subject.title"
                label-cols="12"
                label-cols-sm="6"
                label-size="sm"
                label-for="input-sm"
                class="mb-3"
                v-for="(subject, i) in subjectsOfCurrentSection(section)"
                :key="i"
              >
                <div class="liff-reservation-form__field-value text-right">{{ getFieldValue(subject) }}</div>
              </b-form-group>
            </b-card>
          </template>
        </div>
      </div>

      <div class="footer-action-bar">
        <ShareButton
          variant="s-btn-outline-primary"
          @click="$router.push({ name: 'LiffBookingForm', params: { bookingPresetId } })"
        >
          繼續預約
        </ShareButton>
        <ShareButton
          variant="s-btn-outline-primary"
          @click="$router.push({ name: 'LiffBookingPreset', params: { presetId: bookingPresetId } })"
        >
          預約紀錄
        </ShareButton>
        <MemberButton
          button-text="回官方帳號"
          type="OA"
        >
        </MemberButton>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// import bookingApi from '@/apis/liff/v2/booking'; // NOTE: only enable this when debugging
// import bookingPresetApi from '@/apis/liff/v2/booking-preset'; // NOTE: only enable this when debugging
import ShareButton from "@/components/Page/Liff/Shared/Button";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { themeColorFn } from "@/mixins/liff/themeColor";
import isEmpty from "@/utils/isEmpty";
import { generateModuleConfigGetter } from "@/utils/liff/vueStoreHelpers";
import _ from "lodash";
import deepGet from "lodash/get";
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    ShareButton,
    MemberButton,
    Loading,
  },
  mixins: [
    themeColorFn({ themeConfigPage: 'booking' })
  ],
  data: () => ({
    isLoading: true,
  }),
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
    ...mapState('liffBooking', ['submittedBooking', 'viewingBookingPreset']),
    bookingPresetId() {
      return this.$route.params.bookingPresetId
    },
    // bookingId() {
    //   return 'lq389prn2wxy' // NOTE: only enable this when debugging
    // },
    bookingData() {
      return _.chain(this.submittedBooking).get('data').keyBy('field_id').mapValues('value').value()
    },
    subjects() {
      return deepGet(this.viewingBookingPreset, 'config.fields', []).map(field => ({ id: deepGet(field, 'config._id'), ...field.config, ...field }))
    },
    sections() {
      return deepGet(this.viewingBookingPreset, 'config.sections', [])
    },
    pages() {
      return deepGet(this.viewingBookingPreset, 'config.pages', [])
    },
    hasDefaultPage() {
      const visible = subjectOrSection => isEmpty(subjectOrSection.page_id) && this.passesVisibleCondition(subjectOrSection);
      return this.subjects.filter(visible).filter(subject => false === deepGet(subject, 'dashboard_only', false)).length > 0
        || this.sections.filter(visible).length > 0
        || false === this.hasSetUpFieldMappingForStartAt
        || false === this.hasSetUpFieldMappingForEndAt;
    },
    visiblePages() {
      const pagesFromConfig = _.sortBy(this.pages.filter(this.passesVisibleCondition), 'order');
      return this.hasDefaultPage ? [{ _id: null, title: null, order: 0 }, ...pagesFromConfig] : pagesFromConfig;
    },
    hasSetUpFieldMappingForStartAt() {
      return deepGet(this.viewingBookingPreset, 'config.booking_mapping.bookings.start_at', null) !== null;
    },
    hasSetUpFieldMappingForEndAt() {
      return deepGet(this.viewingBookingPreset, 'config.booking_mapping.bookings.end_at', null) !== null;
    },
  },
  async mounted() {
    // this.setViewingBookingPreset(await bookingPresetApi.get(this.bookingPresetId)) // NOTE: only enable this when debugging
    // this.setSubmittedBooking(await bookingApi.getDetail({ id: this.bookingId })) // NOTE: only enable this when debugging
    this.isLoading = false
  },
  methods: {
    ...mapActions('liffBooking', ['setSubmittedBooking', 'setViewingBookingPreset']),
    getModuleConfig: generateModuleConfigGetter('liff_booking'),
    passesVisibleCondition(subjectOrSectionOrPage) {
      const anyConditions = deepGet(subjectOrSectionOrPage, 'visible_when_any', []);
      if (anyConditions.length === 0) {
        return true;
      }
      return anyConditions.some(condition => this.subjects.some(possibleSubject => {
        return condition.field_id === possibleSubject.id
          && condition.value === this.bookingData[possibleSubject.id];
      }));
    },
    subjectsOfCurrentSection(section) {
      return this.subjects.filter(subject => {
        return subject.section_id === section._id
          && this.passesVisibleCondition(subject)
          && false === deepGet(subject, 'dashboard_only', false)
      });
    },
    subjectsOfCurrentPageWithoutSection(pageId) {
      return this.subjects.filter(subject => {
        return isEmpty(subject.section_id)
          && (isEmpty(pageId) ? isEmpty(subject.page_id) : subject.page_id === pageId)
          && this.passesVisibleCondition(subject)
          && false === deepGet(subject, 'dashboard_only', false)
      });
    },
    sectionsOfCurrentPage(pageId) {
      return this.sections.filter(section => {
        return (isEmpty(pageId) ? isEmpty(section.page_id) : section.page_id === pageId)
          && this.passesVisibleCondition(section)
      });
    },
    getFieldValue(subject) {
      const fieldId = deepGet(subject, 'id')
      const value = deepGet(this.bookingData, fieldId, null)
      if (typeof value === 'object') {
        return deepGet(value, 'text', '')
      } else {
        return value
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}

.liff-reservation-form {
  width: 100%;

  &__card {
    border-radius: 10px;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .card-header {
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #e5e5ea;
      font-size: 16px;
      font-weight: 500;
      padding: 16px 16px 12px 16px;
    }
  }

  &__field-value {
    min-height: 1.5rem;
    border-bottom: 1px solid #e5e5ea;
    line-height: 1.5rem;
    font-size: 0.875rem;
  }
}

.footer-action-bar {
  width: 100%;

  button {
    width: 100%;
  }

  button + button {
    margin-top: 16px;
  }
}
</style>
